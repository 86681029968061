import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const KeybindsPage = () => (
  <Layout>
    <SEO title="Keybinds" />
    <div>
        <p>
            Below are my keybinds. They work for me and have for many years. I've placed everything where it is for a reason and have piggybacked off of the shoulders of many other pro player's binds to make sure everything is in an optimal spot on the keyboard. You might not find them to suit you perfectly but it can be a good reference and starting point if you're not sure where to put your buttons!
        </p>
        <ul style={{
            listStyle: `none`,
        }}>
            <li>W : Forward</li>
            <li>A : Strafe Left</li>
            <li>D : Strafe Right</li>
            <p>(Changing A and D to Strafe instead of Turn opens up two important binds for other abilities! Keyboard Turning is never valuable in the arena. You can also safely unbind your S key to free up another great bind!)</p>
            <br></br>
            <li>` : Gift of the Naaru / StoneForm (Typically my racial ability unless I'm human or night elf)</li>
            <li>1 : Lava Burst (Big Dam = Most important Keybind)</li>
            <li>2 : Healing Surge (This is one of my most pressed abilities and so I have it on a key that I can press with my ring finger, leaving my index and middle fingers open and ready to press other binds)</li>
            <li>3 : Wind Shear (Kick on a non-modified nearby keybind is important for quick access)</li>
            <li>4 : Frost Shock</li>
            <li>5 : Healing Wave</li>
            <li>6 : Hex (6 can be harder to reach and so I put a more situational and less frequently pressed ability here. Hex is incredibly important but not used in every match and I have 3 other binds for it on my mouse)</li>
            <li>R : Purge</li>
            <li>F : Riptide</li>
            <li>T : Healing Stream Totem</li>
            <li>V : Unleash Life</li>
            <li>G : Capacitor Totem</li>
            <br></br>
            <li>Q : Dispel Party 1</li>
            <li>E : Dispel Party 2</li>
            <p>(Dispeling your team members quickly is one of the most important things you can do in the arena. Having these dispels on two of the closest binds to W has been a factor in my success)</p>
            <li>C : Ascendance</li>
            <li>S : Healing Rain</li>
            <li>H : Lightning Bolt (This is not a very important ability and I'm often not in an intense situation when I cast it. I've put it on a bind that I can still hit easily but out of the way and I can even reach with my thumb for low intensity pve which helps reduce the fatigue on my other fingers)</li>
            <li>X : Focus Target (Having this bound can be important against teams that can drop your focus like hunters, rogues, and night elves. You might not always want to move your mouse over to right click the arena frames and this pairs well with the Target Arena binds below)</li>
            <li>Y : Dispel</li>
            <li>Z : Chain Heal</li>
            <br></br>
            <li>Shift-` : Drinking Water</li>
            <li>Shift-1 : Spirit Link Totem (there is argument to be made for putting an important ability like this on a non modified keybind but I simply dont have space for that and I've become used to this bind over the years)</li>
            <li>Shift-2 : Astral Shift</li>
            <li>Shift-3 : Covenant Class Ability</li>
            <li>Shift-4 : Earth Shield</li>
            <li>Shift-5 : Water Shield</li>
            <li>Shift-6 : Skyfury Totem / Counterstrike Totem</li>
            <li>Shift-R : Earthbind Totem</li>
            <li>Shift-F : Flame Shock</li>
            <li>Shift-T : Earthen Wall Totem</li>
            <li>Shift-V : Ghost Wolf</li>
            <li>Shift-G : Earthgrab Totem</li>
            <br></br>
            <li>Shift-Q : Chain Lightning</li>
            <li>Shift-E : Gladiator’s Medallion</li>
            <br></br>
            <li>Shift-S : Covenant Ability</li>
            <li>Shift-H : Healthstone (don't forget to use these when you're playing with warlocks!)</li>
            <br></br>
            <li>Shift-Z : Resurrection</li>
            <br></br>
            <li>Alt-1 : Target Arena 1</li>
            <li>Alt-2 : Target Arena 2</li>
            <li>Alt-3 : Target Arena 3</li>
            <li>Alt-4 : Spiritwalker’s Grace</li>
            <li>Alt-5 : Flametongue Weapon (not important)</li>
            <li>Alt-6 : Wolfywolf the Frostwolf Ghostpup Pet (extremely important)</li>
            <li>Alt-R : Mount</li>
            <li>Alt-F : Cancelaura Blessing of Protection macro (to prevent mage spellsteal)</li>
            <li>Alt-T : On-use Trinket (Battlemaster’s / Maledict / Badge)</li>
            <li>Alt-V : Lightning Shield</li>
            <li>Alt-G : Healing Tide Totem</li>
            <br></br>
            <li>Alt-Q : Heroism</li>
            <li>Alt-E : Unleash Water / Situational PvP talent</li>
            <br></br>
            <li>Alt-S : Water Walking</li>
            <br></br>
            <li>Ctrl-1 : Cosmetic</li>
            <li>Ctrl-2 : Mana Tide Totem</li>
            <li>Ctrl-3 : Cosmetic</li>
            <li>Ctrl-4 : Extra Action Button 1</li>
            <br></br>
            <li>MouseWheelUp : Grounding Totem</li>
            <li>MouseWheelDown : Tremor Totem</li>
            <p>(The mousewheel is easy to spam at high apm to ensure the ability gets used at the soonest possible moment, especially when coming out of a CC. Grounding/Tremor are the most frequent and important abilities that I do this with)</p>
            <li>SideMouseButton1 : Wind Shear Arena 1</li>
            <li>SideMouseButton2 : Wind Shear Arena 2</li>
            <li>SideMouseButton3 : Wind Shear Arena 3</li>
            <li>SideMouseButton4 : Hex Arena 1</li>
            <li>SideMouseButton5 : Hex Arena 2</li>
            <li>SideMouseButton6 : Hex Arena 3</li>
            <li>SideMouseButton7 :  Toggle Autorun</li>
            <br></br>
            <li>Delete : Hearthstone</li>
            <br></br>
            <li>Tab : Target Nearest Enemy Player (it can be valuable to change your tab target from nearest enemy to nearest enemy player for quicker player targeting when pets and other minions are around)</li>
            <br></br>
            <li>F1 : Target Self (most of the time I click off my target to self cast)</li>
            <li>F2 : Target Party 1</li>
            <li>F3 : Target Party 2</li>
        </ul>
    </div>
  </Layout>
)

export default KeybindsPage
